import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./clan1.css";

import avatar from "../../Images/gift.gif";

const Clan1 = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="C1Super">
      <div className="C1Container">
        <div className="C1Title">
          <div
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-offset="200"
            data-aos-delay="100"
          >
            <h2>NO TAX</h2>
          </div>
          <div
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-offset="200"
            data-aos-delay="100"
          >
          </div>
        </div>
        <div className="C1SubContainer">
          <div
            className="C1Image"
            data-aos="fade"
            data-aos-offset="200"
            data-aos-easing="ease-in-out"
            data-aos-delay="100"
          >
            <img src={avatar} alt="" />
          </div>
          <div
            className="C1Text"
            data-aos="fade"
            data-aos-offset="200"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
          // data-aos-delay="1000"
          >
            <p>
              0% tax and fast tx provided by SOLANA amazing tps
            </p>
            {/* <h4>-Mau Yaseryo, Mau Clan kata master.</h4> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clan1;
