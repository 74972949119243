import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./world.css";

import logo from "../../Images/logo_1.png"


const World = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="WSuper">
      <div className="WContainer">
        <div
          className="WTitle"
          data-aos="fade-down"
          data-aos-easing="ease-in-out"
          data-aos-delay="100"
        >
          <h2>Duki the duck</h2>
        </div>
        <div className="WText">
          <div
            className="WTParagraph"
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-delay="1000"
          >
            <img src={logo} alt="image"/>
            </div>
          <div
            className="WTParagraph"
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-delay="2000"
          >
            <p>
            The new duck in town
            </p>
          </div>
        </div>
        <div
          className="WImage"
          data-aos="fade"
          data-aos-easing="ease-in-out"
          data-aos-delay="500"
        >
          {/* <img src={world} alt="" /> */}
        </div>
      </div>
    </div>
  );
};

export default World;
