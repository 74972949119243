import { useEffect, useState } from "react";

import Navbar from "./Components/Navbar";
import Hero from "./Components/Hero";
import World from "./Components/World";
import Clan1 from "./Components/Clan1";
import Clan2 from "./Components/Clan2";
import Clan3 from "./Components/Clan3";
import Footer from "./Components/Footer";
import Player from "./Components/Player";


import "./App.css";

function App() {
  const [loading, setLoading] = useState(true);
  const [userClicked, setUserClicked] = useState(false);

  useEffect(() => {
    if (userClicked) {
      setTimeout(() => {
        setLoading(false);
      }, 6000);
    }
  }, [userClicked]);

  const handleClick = () => {
    setUserClicked(true);
  };

  return (
    <>
        <div className="App">
          <Navbar />
          <Hero />
          <World />
          <Clan1 />
          <Clan2 />
          <Clan3 />
          <Footer />
          {/* <Player /> */}
        </div>
    </>
  );
}

export default App;
